/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, FC } from 'react';
import { Row, Col, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { css } from '@emotion/react/macro';

import { Page } from '../components';
import { useStoreActions, useStoreState } from '../hooks';
import { Demand, MarketNews, Supply } from '../components/dailyNews/kpis';
import {
  DailyNewsAreaFilters,
  DailyNewsSubareaFilter,
} from '../components/dailyNews/DailyNewsFilters';
import { SubareaEnum } from '../api/enums';
import { SimplifiedProjects } from '../store/market';
import { Map } from '../components/addReport/Map';
import { MapType } from '../components/MapControls/MapProjectStatusTags';

const styles = {
  icon: css({
    width: 24,
    height: 24,
    strokeWidth: '2px',
    color: '#313B5E',
  }),
};

export const DailyNewsPage: FC = () => {
  const { fetchBlockData, fetchBlockDashboardData } = useStoreActions(
    (actions) => actions.dailyNews,
  );
  const { isMapLoading } = useStoreState((state) => state.market);
  const { isLoading } = useStoreState((state) => state.global);
  const { area } = useStoreState((state) => state.filters);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { currentDashboardData, subarea, partialData } = useStoreState(
    (state) => state.dailyNews,
  );
  const { profile } = useStoreState((state) => state.user);
  const { price_per_sm_calculation } = profile || {};
  const { selectedReportId: dashboardId, paramsPM } = useStoreState(
    (state) => state.filters,
  );
  const { fetchDashboardOverview, fetchDashboardSettings } = useStoreActions(
    (action) => action.market,
  );
  const projects = useStoreState((state) =>
    state.market.simplifiedProjects.filter(
      ({ gps_latitude, gps_longitude, include }) =>
        gps_latitude && gps_longitude && include,
    ),
  );
  useEffect(() => {
    if (area) {
      fetchBlockData({ cityId: area, segment: 'graph_data' });
      fetchBlockData({ cityId: area, segment: 'discounts' });
      fetchBlockData({ cityId: area, segment: 'added_units' });
      fetchBlockData({ cityId: area, segment: 'new_projects' });
      fetchBlockData({ cityId: area, segment: 'new_sold_out_projects' });
      fetchBlockData({ cityId: area, segment: 'top_sellers_last_month' });
    }
  }, [area, fetchBlockData]);

  useEffect(() => {
    if (dashboardId) {
      fetchBlockDashboardData({ dashboardId, segment: 'graph_data' });
      fetchBlockDashboardData({ dashboardId, segment: 'discounts' });
      fetchBlockDashboardData({ dashboardId, segment: 'added_units' });
      fetchBlockDashboardData({ dashboardId, segment: 'new_projects' });
      fetchBlockDashboardData({
        dashboardId,
        segment: 'new_sold_out_projects',
      });
      fetchBlockDashboardData({
        dashboardId,
        segment: 'top_sellers_last_month',
      });
    } else {
      fetchBlockDashboardData({ dashboardId: area, segment: 'graph_data' });
      fetchBlockDashboardData({ dashboardId: area, segment: 'discounts' });
      fetchBlockDashboardData({ dashboardId: area, segment: 'added_units' });
      fetchBlockDashboardData({ dashboardId: area, segment: 'new_projects' });
      fetchBlockDashboardData({
        dashboardId: area,
        segment: 'new_sold_out_projects',
      });
      fetchBlockDashboardData({
        dashboardId: area,
        segment: 'top_sellers_last_month',
      });
    }
  }, [area, dashboardId, fetchBlockDashboardData]);

  useEffect(() => {
    if (area && paramsPM) {
      fetchDashboardSettings({
        dashboardId: area,
        date: paramsPM.date,
      });
    }
  }, [
    area,
    fetchDashboardSettings,
    language,
    price_per_sm_calculation,
    paramsPM,
  ]);

  useEffect(() => {
    if (dashboardId) {
      fetchDashboardOverview({
        dashboardId,
        date: new Date().toISOString().split('T')[0],
      });
    }
  }, [dashboardId, fetchDashboardOverview]);

  const mappedData: SimplifiedProjects[] = useMemo(() => {
    if (!projects || projects.length === 0) return [];
    return projects
      .map((p) => ({
        ...p,
      }))
      .filter((d) => {
        if (subarea && area === 517 && partialData) {
          if (subarea === SubareaEnum.CITY) {
            return partialData.city_ids?.includes(d.project_id);
          }
          if (subarea === SubareaEnum.OUTSKIRT) {
            return partialData.outskirts_ids?.includes(d.project_id);
          }
          return true;
        }
        return true;
      });
  }, [area, partialData, projects, subarea]);

  return (
    <Page
      pageClassname='dashboard'
      title={t('daily_news.title')}
      filters={
        <>
          <DailyNewsAreaFilters />
          <DailyNewsSubareaFilter />
          <CalendarIcon css={styles.icon} />
          <div className='text-bmblue'>
            <span style={{ fontWeight: 700 }}>
              {' '}
              {new Date().toLocaleDateString(language, {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
              })}
            </span>
          </div>
        </>
      }
    >
      {!isLoading && currentDashboardData ? (
        <Row className='dashboard-container' gutter={[16, 16]}>
          <Col xs={12} md={12}>
            <Supply />
          </Col>
          <Col xs={12} md={12}>
            <Demand />
          </Col>
          <Col flex='auto' xs={24}>
            <Row gutter={[16, 16]}>
              <Col xs={12} md={12}>
                <MarketNews />
                {/* <CompetitorsNews /> */}
              </Col>
              <Col xs={12} md={12}>
                {!isMapLoading && mappedData && mappedData.length > 0 && (
                  <Map projects={mappedData} type={MapType.DAILY_NEWS} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Spin spinning size='large' tip={t('loading')} />
      )}
    </Page>
  );
};
