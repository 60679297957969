import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoPopover } from '../../../../../InfoPopover';

export const RideToCenter: FC<{ region: string }> = ({ region }) => {
  const { t } = useTranslation();

  return (
    <div>
      <InfoPopover
        label={t('project.overview.ride_to_center', 'Ride to Center')}
        popoverProps={{
          content: t(
            'project.overview.info.ride_to_center',
            'The time it takes to get to the center of {{region}} by car on a workday.',
            { region },
          ),
        }}
      />
    </div>
  );
};
