import { LanguageEnum } from '../../api/enums';

export type City = 'Bratislava' | 'Praha';

type LanguageDataType = {
  downloadUrl: string;
  embedUrl: string;
};

type LanguageQuartalType = Record<LanguageEnum, LanguageDataType>;

type QuartalType = Record<string, LanguageQuartalType>;

type CityQuartalType = Record<City, QuartalType>;

export const reports: {
  sk: Exclude<CityQuartalType, 'Praha'>;
  cs: Exclude<CityQuartalType, 'Bratislava'>;
} = {
  sk: {
    Slovensko: {
      // @ts-ignore
      '2024 Q2': {
        sk: {
          downloadUrl:
            'https://www.builtmind.com/wp-content/uploads/2024/09/Slovensko-2024-Q2-Report.pdf',
          embedUrl:
            'https://www.canva.com/design/DAGRMF0bUxI/UrFVEHdAmCqMZ3WK4AsGbg/view?embed',
        },
      },
    },
    Bratislava: {
      // @ts-ignore
      '2024 Q2': {
        sk: {
          downloadUrl:
            'https://www.builtmind.com/wp-content/uploads/2024/07/Bratislava-2024-Q2-Report.pdf',
          embedUrl:
            'https://www.canva.com/design/DAGKcx7Gl0w/7SZ7yaBTzWrXRS4qvF3r1Q/view?embed',
        },
      },
      // @ts-ignore
      '2024 Q2': {
        sk: {
          downloadUrl:
            'https://www.builtmind.com/wp-content/uploads/2024/07/Bratislava-2024-Q2-Report.pdf',
          embedUrl:
            'https://www.canva.com/design/DAGKcx7Gl0w/7SZ7yaBTzWrXRS4qvF3r1Q/view?embed',
        },
      },
      // @ts-ignore
      '2024 Q1': {
        sk: {
          downloadUrl:
            'https://www.builtmind.com/wp-content/uploads/2024/05/Bratislava-2024-Q1-Report.pdf',
          embedUrl:
            'https://www.canva.com/design/DAGCLwAi4Dw/4b_FtRUgmN8jpbwZYkid3g/view?embed',
        },
      },
      // @ts-ignore
      '2023 Q4': {
        sk: {
          downloadUrl:
            'https://www.builtmind.com/wp-content/uploads/2024/02/Bratislava-2023-Q4-Report-BuiltMind.pdf',
          embedUrl: 'https://www.canva.com/design/DAF659T1gOg/view?embed',
        },
        en: {
          downloadUrl:
            'https://www.builtmind.com/wp-content/uploads/2024/02/Bratislava-2023-Q4-Report-BuiltMind-EN.pdf',
          embedUrl:
            'https://www.canva.com/design/DAF9EV4eMyI/3ZrCnf_OePWW19hfTmCQQg/view?embed',
        },
      },
    },
    // Praha: {
    //   // @ts-ignore
    //   '2023 Q4': {
    //     en: {
    //       downloadUrl: 'https://www.builtmind.com/wp-content/uploads/2024/03/BuiltMind-Prague-2023-Q4-Report_EN.pdf',
    //       embedUrl: 'https://www.canva.com/design/DAF-iJD-hTo/o3sOxSbAOs35WaEMRmn1xQ/view?embed',
    //       },
    //     cs: {
    //         downloadUrl: 'https://www.builtmind.com/wp-content/uploads/2024/03/BuiltMind-Praha-2023-Q4-Report.pdf',
    //         embedUrl: 'https://www.canva.com/design/DAF6tTC1pKM/ageL6Y5CiwfpbUBeKKlDbQ/view?embed',
    //       },
    //     },
    // },
  },
  cs: {
    Praha: {
      // @ts-ignore
      '2024 Q2': {
        cs: {
          downloadUrl:
            'https://www.builtmind.com/wp-content/uploads/2024/07/Praha-2024-Q2-Report.pdf',
          embedUrl:
            'https://www.canva.com/design/DAGKh9dIPkA/Lg9zVlTR-0GaMN4Vxt5xFA/view?embed',
        },
      },
      // @ts-ignore
      '2024 Q1': {
        cs: {
          downloadUrl:
            'https://www.builtmind.com/wp-content/uploads/2024/05/Praha-2024-Q1-Report.pdf',
          embedUrl:
            'https://www.canva.com/design/DAGCLzAzA5w/aO_SfdhKArzeMIUrdPLAZw/view?embed',
        },
      },
      // @ts-ignore
      '2023 Q4': {
        en: {
          downloadUrl:
            'https://www.builtmind.com/wp-content/uploads/2024/03/BuiltMind-Prague-2023-Q4-Report_EN.pdf',
          embedUrl:
            'https://www.canva.com/design/DAF-iJD-hTo/o3sOxSbAOs35WaEMRmn1xQ/view?embed',
        },
        cs: {
          downloadUrl:
            'https://www.builtmind.com/wp-content/uploads/2024/03/BuiltMind-Praha-2023-Q4-Report.pdf',
          embedUrl:
            'https://www.canva.com/design/DAF6tTC1pKM/ageL6Y5CiwfpbUBeKKlDbQ/view?embed',
        },
      },
    },
  },
};

export const getAnalysisData = (
  localization: 'sk' | 'cs',
  city: City,
  quartal: string,
  language: LanguageEnum,
): LanguageDataType => reports[localization][city][quartal][language || 'sk'];
