import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Row, Col, Segmented, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { SegmentedValue } from 'antd/lib/segmented';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { Card, Page } from '../../components';
import { Reports } from '../../components/market';
import {
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../hooks';
import { ProjectsTable } from '../../components/market/ProjectsTable';
import { Mode } from '../../store/types';
import { MarketProjectsFilters } from './MarketProjectsFilters';
import { SubscribePage } from '../../components/SubscribePage/SubscribePage';
import { ColumnFiltersList } from '../../components/ColumnFiltersList/ColumnFiltersList';
import { onSaveColumnFilters } from '../../components/ColumnFiltersList/columnFilters';
import { Map } from '../../components/addReport/Map';
import { MapType } from '../../components/MapControls/MapProjectStatusTags';

enum ColumnSwitchValue {
  PRICE,
  PRICE_PER_SM,
}

export const MarketProjects: FC = () => {
  const isMarketEnabled = useStoreState(
    (state) => state.user.profile?.market_enabled,
  );
  const simplifiedProjects = useStoreState((state) =>
    state.market.simplifiedProjects.filter(
      ({ gps_latitude, gps_longitude, include }) =>
        gps_latitude && gps_longitude && include,
    ),
  );
  const mode = useStoreState((state) => state.market.mode);
  const isMaximized = useStoreState((state) => state.market.isMaximized);
  const isReadMode = useStoreState((state) => state.market.mode === Mode.READ);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const paramsSM = useStoreState((state) => state.filters.paramsSM);
  const fetchDashboardSettings = useStoreActions(
    (actions) => actions.market.fetchDashboardSettings,
  );
  const primaryParameters = useStoreState(
    (state) => state.market.primaryParameters,
  );
  const secondaryParameters = useStoreState(
    (state) => state.market.secondaryParameters,
  );
  const { areaUnit } = useRegionFormatting();
  const showPricePerSM = useStoreState((state) => state.market.showPricePerSM);

  const setIsMaximized = useStoreActions(
    (actions) => actions.market.setIsMaximized,
  );
  const setShowPricePerSM = useStoreActions(
    (actions) => actions.market.setShowPricePerSM,
  );
  const fetchUserTemplates = useStoreActions(
    (actions) => actions.market.fetchUserTemplates,
  );
  const { t } = useTranslation();
  const [isColumnFilterVisible, setIsColumnFilterVisible] = useState(false);
  // HINT: columnsFilterValues seems to be inverted to me
  const [columnsFilterValues, setColumnFilterValues] = useState<string[]>([]);
  const [columnDataIndexes, setColumnDataIndexes] = useState<string[]>([]);

  useEffect(() => {
    setColumnDataIndexes([]);
  }, [isMaximized]);

  const onChangeFilter = (value: string): void => {
    if (columnsFilterValues.includes(value)) {
      setColumnFilterValues(columnsFilterValues.filter((val) => val !== value));
    } else {
      if (columnDataIndexes.length - columnsFilterValues.length === 1) return;
      setColumnFilterValues((prev) => [...prev, value]);
    }
  };

  const onSaveFilters = (): void => {
    onSaveColumnFilters(
      'projectsDataIndexes',
      columnDataIndexes,
      'projectsFilters',
      columnsFilterValues,
    );
    setIsColumnFilterVisible(false);
  };

  const onResetFilters = (): void => {
    localStorage.removeItem('projectsDataIndexes');
    localStorage.removeItem('projectsFilters');
    setColumnFilterValues([]);
  };

  const titleExtra = useMemo(() => {
    const handleChange = (value: SegmentedValue): void => {
      setShowPricePerSM(value === ColumnSwitchValue.PRICE_PER_SM);
    };
    const handleMaximize = (): void => setIsMaximized(!isMaximized);
    return (
      <div className='title-extra'>
        <div className='segmented-switch'>
          <Segmented
            value={
              showPricePerSM
                ? ColumnSwitchValue.PRICE_PER_SM
                : ColumnSwitchValue.PRICE
            }
            options={[
              {
                value: ColumnSwitchValue.PRICE,
                label: t('common.price', 'Price'),
              },
              {
                value: ColumnSwitchValue.PRICE_PER_SM,
                label: t('common.price_per_unit', 'Price per {{unit}}', {
                  unit: areaUnit,
                }),
              },
            ]}
            onChange={handleChange}
          />
        </div>
        <div className='icon'>
          {isMaximized ? (
            <FullscreenExitOutlined onClick={handleMaximize} />
          ) : (
            <FullscreenOutlined onClick={handleMaximize} />
          )}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areaUnit,
    isColumnFilterVisible,
    isMaximized,
    setIsMaximized,
    setShowPricePerSM,
    showPricePerSM,
    t,
  ]);

  const FilterTitleButton = (): ReactNode => (
    <div className='flex'>
      {titleExtra}
      <div
        className='items-center flex cursor-pointer z-10 ml-2'
        onClick={() => setIsColumnFilterVisible(!isColumnFilterVisible)}
      >
        <EllipsisHorizontalIcon className='w-12 storke-2 text-[#828282]' />
      </div>
      {isColumnFilterVisible && (
        <div className='absolute right-0 top-14 flex flex-col bg-white rounded-2xl shadow-xl p-4 min-w-[300px] z-20 space-y-6 text-bmblue select-none'>
          <span className='font-bold p-4 flex items-center'>
            Selected Columns
          </span>
          <ColumnFiltersList
            isMarketProjects
            columnDataIndexes={columnDataIndexes}
            setColumnDataIndexes={setColumnDataIndexes}
            columnsFilterValues={columnsFilterValues}
            onChangeFilter={onChangeFilter}
          />
          <div className='flex justify-evenly items-center space-x-4 p-4'>
            <Button
              className='bm-default-btn'
              onClick={() => setIsColumnFilterVisible(false)}
            >
              Cancel
            </Button>
            <Button className='bm-default-btn' onClick={() => onResetFilters()}>
              Reset
            </Button>
            <Button className='bm-submit-btn' onClick={() => onSaveFilters()}>
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  // Fetch dashboard settings when paramsPM or paramsSM change
  useEffect(() => {
    if (
      isMarketEnabled &&
      (paramsPM || paramsSM) &&
      mode !== Mode.CREATE &&
      mode !== Mode.EDIT
    ) {
      fetchDashboardSettings(paramsPM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchDashboardSettings,
    JSON.stringify(paramsPM),
    mode,
    paramsSM,
    isMarketEnabled,
  ]);

  useEffect(() => {
    fetchUserTemplates();
  }, [fetchUserTemplates]);

  const showMapOnly =
    (!primaryParameters && !!secondaryParameters) || (!paramsPM && !!paramsSM);
  return (
    <Page
      pageClassname='market-projects'
      headerClass='justify-normal'
      title={t('market.title')}
      filters={isMarketEnabled && <MarketProjectsFilters />}
    >
      {isMarketEnabled ? (
        <div className='report-container'>
          <Reports showActions showAddReport />
          <Row className='tables-and-map' gutter={16}>
            {isMaximized && !showMapOnly && (
              <Col className='tables' span={24}>
                <Card
                  className='table-wrapper'
                  titleExtra={FilterTitleButton()}
                >
                  <ProjectsTable
                    isReadMode={isReadMode}
                    extended
                    setColumnDataIndexes={setColumnDataIndexes}
                    setColumnFilterValues={setColumnFilterValues}
                    columnDataIndexes={columnDataIndexes}
                    columnsFilterValues={columnsFilterValues}
                  />
                </Card>
              </Col>
            )}
            {!isMaximized && !showMapOnly && simplifiedProjects && (
              <>
                <Col className='tables' span={12}>
                  <Card
                    className='table-wrapper'
                    titleExtra={FilterTitleButton()}
                  >
                    <ProjectsTable
                      isReadMode={isReadMode}
                      setColumnDataIndexes={setColumnDataIndexes}
                      setColumnFilterValues={setColumnFilterValues}
                      columnDataIndexes={columnDataIndexes}
                      columnsFilterValues={columnsFilterValues}
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Map projects={simplifiedProjects} type={MapType.PROJECTS} />
                </Col>
              </>
            )}
            {showMapOnly && simplifiedProjects && (
              <Col span={24}>
                <Map projects={simplifiedProjects} type={MapType.PROJECTS} />
              </Col>
            )}
          </Row>
        </div>
      ) : (
        <SubscribePage featureType='market' />
      )}
    </Page>
  );
};
