import { FC, ReactNode, useMemo } from 'react';
import { Row, Col } from 'antd';
import { t } from 'i18next';
import { useRegionFormatting, useStoreState } from '../../../../../../hooks';
import { Materials } from './Materials';
import { Parking } from './Parking';
import { Payments } from './Payments';
import { Data } from '../../types';
import { BuildingUse } from './BuildingUse';
import { CountryEnum } from '../../../../../../api/enums';
import { RideToCenter } from './RideToCenter';
import { PublicTransport } from './PublicTransport';

interface RowData {
  label: string | ReactNode;
  value: ReactNode;
}

interface Props {
  data: Data;
}

export const Description: FC<Props> = ({
  data: {
    cellar_price,
    cellar_price_per_sm,
    cellar_included,
    date_construction_completion,
    date_sale_start,
    materials,
    max_price_per_sm,
    min_price_per_sm,
    parking_indoor_price,
    parking_outdoor_price,
    payment_construction,
    payment_contract,
    payment_occupancy,
    public_transport_to_center,
    ride_to_center,
    total_number_of_units,
    parking_included,
    renovation,
    building_use,
    cooperative_housing,
    unit_type,
    city,
  },
}) => {
  const { formatCurrency, areaUnit } = useRegionFormatting();
  const { profile } = useStoreState((state) => state.user);

  const showAttributes =
    profile?.country === CountryEnum.CZECHIA ||
    profile?.country === CountryEnum.SLOVAKIA;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCellarValue = (): string => {
    if (cellar_price) {
      return `${formatCurrency(cellar_price)}`;
    }
    if (cellar_price_per_sm) {
      return `${formatCurrency(cellar_price_per_sm)} / ${areaUnit}`;
    }
    if (cellar_included) {
      return t('enums.state.included');
    }
    return '-';
  };

  const data: RowData[] = useMemo(
    () => [
      {
        label: t('project.overview.materials', 'Materials'),
        value: materials ? <Materials data={materials} /> : '-',
      },
      {
        label: t('common.price_per_unit', 'Price per {{unit}}', {
          unit: areaUnit,
        }),
        value:
          !min_price_per_sm && !max_price_per_sm
            ? '-'
            : `${min_price_per_sm && `${formatCurrency(min_price_per_sm)} - `}${
                max_price_per_sm && formatCurrency(max_price_per_sm)
              }`,
      },
      {
        label: t('project.overview.type', 'Type'),
        value: unit_type
          ? t(`project.overview.type.${unit_type}`, unit_type)
          : '-',
      },
      {
        label: t('project.overview.units_count', 'Units Count'),
        value: total_number_of_units ?? '-',
      },
      {
        label: t('project.overview.completion', 'Completion'),
        value: date_construction_completion ?? '-',
      },
      {
        label: t('project.overview.parking', 'Parking'),
        value: parking_included ? (
          t('enums.state.included')
        ) : (
          <Parking
            parking_indoor_price={parking_indoor_price}
            parking_outdoor_price={parking_outdoor_price}
          />
        ),
      },
      ...(showAttributes
        ? [
            {
              label: t('project.overview.sale_start', 'Sale start'),
              value: date_sale_start ?? '-',
            },
          ]
        : []),
      {
        label: <RideToCenter region={city ?? ''} />,
        value: ride_to_center
          ? t('project.overview.minutes', '{{value}} mins', {
              value: ride_to_center,
            })
          : '-',
      },
      {
        label: t('project.overview.cellar', 'Cellar'),
        value: getCellarValue(),
      },
      {
        label: <PublicTransport region={city ?? ''} />,
        value: public_transport_to_center
          ? t('project.overview.minutes', '{{value}} mins', {
              value: public_transport_to_center,
            })
          : '-',
      },
      ...(showAttributes
        ? [
            {
              label: t('project.overview.payments', 'Payments'),
              value:
                payment_construction ||
                payment_contract ||
                payment_occupancy ? (
                  <Payments
                    payment_construction={payment_construction}
                    payment_contract={payment_contract}
                    payment_occupancy={payment_occupancy}
                  />
                ) : (
                  '-'
                ),
            },
          ]
        : []),
      {
        label: t('project.overview.construction'),
        value:
          typeof renovation !== 'undefined'
            ? t(`project.overview.${renovation ? 'renovation' : 'newly_built'}`)
            : '',
      },
      {
        label: t('project.overview.building_use.title'),
        value: building_use ? <BuildingUse building_use={building_use} /> : '',
      },
      ...(showAttributes
        ? [
            {
              label: t('project.ownership', 'Ownership'),
              value:
                cooperative_housing === true
                  ? t('project.ownership.cooperative')
                  : t('project.ownership.private'),
            },
          ]
        : []),
    ],
    [
      areaUnit,
      getCellarValue,
      date_construction_completion,
      formatCurrency,
      materials,
      max_price_per_sm,
      min_price_per_sm,
      parking_indoor_price,
      parking_outdoor_price,
      parking_included,
      payment_construction,
      payment_contract,
      payment_occupancy,
      public_transport_to_center,
      date_sale_start,
      ride_to_center,
      total_number_of_units,
      renovation,
      building_use,
      cooperative_housing,
      unit_type,
    ],
  );
  return (
    <Row>
      {data.map(({ label, value }, index) => (
        <Col key={label?.toString()} span={index % 2 === 0 ? 13 : 11}>
          <Row>
            <Col span={11}>
              <b>{label}</b>
            </Col>
            <Col span={13}>{value}</Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};
