import { Action, action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { fetchOverviewData, getReportsSidebarOrdering } from '../api';
import { MarketDashboard, OverviewData } from '../api/mockData/overviewData';
import { getUpdateFrequencyDates, logError, sortByString, sortReportsByOrder } from '../utils/utils';
import { MarketParams, ProjectType, SecondaryMarketParams, StoreModel, Territory } from './types';
import { FilterEnum } from '../components/project/useFilter';

export interface FilterStore {
  projectId?: number;
  phaseId?: number;
  area?: number;
  isLoading: boolean;
  aggDate?: string;
  projectType: Computed<FilterStore, ProjectType, StoreModel>;
  overviewData?: OverviewData;
  paramsPM: MarketParams | null;
  paramsSM: SecondaryMarketParams | null;
  marketDates: Computed<FilterStore, string[], StoreModel>;
  aggregationDates: Computed<FilterStore, string[], StoreModel>;
  setOverviewData: Action<FilterStore, OverviewData>;
  setAggDate: Action<FilterStore, string>;
  setParamsPM: Action<FilterStore, MarketParams | null>;
  setParamsSM: Action<FilterStore, SecondaryMarketParams | null>;
  setProjectId: Action<FilterStore, number>;
  setPhaseId: Action<FilterStore, number>;
  setArea: Action<FilterStore, number>;
  setLoading: Action<FilterStore, boolean>;
  fetchOverviewData: Thunk<FilterStore, undefined, unknown, StoreModel>;
  availability: FilterEnum;
  setAvailability: Action<FilterStore, FilterEnum>;
  displayValues: boolean;
  setDisplayValues: Action<FilterStore, boolean>;
  separateUnderConstruction: boolean;
  setSeparateUnderConstruction: Action<FilterStore, boolean>;
  selectedReportId?: number;
  setSelectedReportId: Action<FilterStore, number>;
  selectedReportName?: string;
  setSelectedReportName: Action<FilterStore, string>;
  territories?: Territory[];
  setTerritories: Action<FilterStore, Territory[]>;
  unitsType: string[];
  setUnitsType: Action<FilterStore, string[]>;
}
export const filterStore: FilterStore = {
  isLoading: false,
  paramsPM: null,
  paramsSM: null,
  marketDates: computed(
    [
      (state) => state.overviewData,
      (state, globalState) => globalState.filters.paramsPM?.dashboardId,
    ],
    (overviewData, dashboardId) =>
      overviewData?.market_dashboards.find((md) => md.id === dashboardId)
        ?.dates ?? [],
  ),
  projectType: computed(
    [
      (state) => state.projectId,
      (state) => state.overviewData,
    ],
    (projectId, overviewData) =>
      overviewData?.projects
        .find((p) => p.project_id === projectId)
        ?.project_type === ProjectType.RENT
        ? ProjectType.RENT
        : ProjectType.SALE,
  ),
  aggregationDates: computed(
    [
      (state) => state.phaseId,
      (state) => state.projectId,
      (state) => state.overviewData,
    ],
    (phaseId, projectId, overviewData) =>
      overviewData?.projects
        .find((p) => p.project_id === projectId)
        ?.phases.find((p) => p.phase_id === phaseId)?.dashboard_dates ?? [],
  ),
  setOverviewData: action((state, data) => {
    state.overviewData = data;
  }),
  setProjectId: action((state, value) => {
    const newPhase = state.overviewData?.projects.find(
      (project) => project.project_id === value,
    )?.phases;
    if (newPhase && newPhase.length > 0) {
      state.phaseId = newPhase[0].phase_id;
    }
    state.projectId = value;
  }),
  setPhaseId: action((state, value) => {
    state.phaseId = value;
  }),
  setArea: action((state, value) => {
    state.area = value;
  }),
  setAggDate: action((state, value) => {
    state.aggDate = value;
  }),
  setParamsPM: action((state, value) => {
    state.paramsPM = value;
  }),
  setParamsSM: action((state, value) => {
    state.paramsSM = value;
  }),
  setLoading: action((state, value) => {
    state.isLoading = value;
  }),
  fetchOverviewData: thunk(async (actions, payload, helpers) => {
    const {
      user: { profile },
      filters: { projectId },
    } = helpers.getStoreState();
    const {
      global: { setGlobalLoading },
      filters: { setParamsPM, setSelectedReportName },
      market: { setReportsOrder },
    } = helpers.getStoreActions();
    if (!profile) {
      return;
    }
    try {
      actions.setLoading(true);
      setGlobalLoading(true);
      const reportsOrder = await getReportsSidebarOrdering();
      setReportsOrder(reportsOrder.map((report) => report.dashboard_name));
      const { data } = await fetchOverviewData(profile.developer_id);

      data.market_dashboards = sortReportsByOrder(data.market_dashboards, reportsOrder.map((report) => report.dashboard_name));
      data.market_dashboards.sort(
        ({ default: defaultA }, { default: defaultB }) =>
          Number(defaultB) - Number(defaultA),
      );
      const namedUpdates = data.updates
        ? data.updates.map((update) => {
          const existingProject = data.projects.find(
            (project) => project.project_id === update.project_id,
          );
          return {
            ...update,
            name: existingProject?.name,
          };
        })
        : [];
      actions.setOverviewData({
        ...data,
        updates: namedUpdates,
      });
      // set market params if they are not present
      const defaultDashboard = data.market_dashboards.find(
        (dashboard) => {
          if (reportsOrder[0]) return dashboard.name === reportsOrder[0]?.dashboard_name;
          return dashboard.projects_count !== -1;
        });
     if (data) {
      const { market_dashboard_frequency: frequency = 'daily' } = data;
        if (defaultDashboard) {
          const defaultDashboardDates =
            defaultDashboard.dates.sort(sortByString);
          const validDates = getUpdateFrequencyDates(
            defaultDashboardDates, frequency,
          );
          setParamsPM({
            dashboardId: defaultDashboard.id,
            date: validDates[validDates.length - 1],
            name: defaultDashboard.name,
          });
          // setSelectedReportName(defaultDashboard.name);
        }
      // }
     }

      // resolve default dashboard aggregation date
      const currentProject = data.projects?.find((value) => projectId ? value.project_id === projectId : value.actual);
      if (currentProject) {
        const { phases, project_id } = currentProject;
        actions.setProjectId(project_id);
        const currentPhase = phases?.find((value) => value.actual);
        if (currentPhase) {
          const { phase_id, dashboard_dates } = currentPhase;
          if (dashboard_dates.length > 1) {
            actions.setAggDate(
              dashboard_dates.sort()[dashboard_dates.length - 1],
            );
          } else {
            actions.setAggDate(dashboard_dates[0]);
          }
          actions.setPhaseId(phase_id);
        }
      }
    } catch (error) {
      logError(error);
    } finally {
      actions.setLoading(false);
      setGlobalLoading(false);
    }
  }),
  availability: FilterEnum.AVAILABLE,
  setAvailability: action((state, newAvailability: FilterEnum) => {
    state.availability = newAvailability;
  }),
  displayValues: false,
  setDisplayValues: action((state, value) => {
    state.displayValues = value;
  }),
  separateUnderConstruction: false,
  setSeparateUnderConstruction: action((state, value) => {
    state.separateUnderConstruction = value;
  }),
  setSelectedReportId: action((state, id) => {
    state.selectedReportId = id;
  }),
  setSelectedReportName: action((state, name) => {
    state.selectedReportName = name;
  }),
  setTerritories: action((state, territories) => {
    state.territories = territories;
  }),
  unitsType: ['flat'],
  setUnitsType: action((state, value) => {
    state.unitsType = value;
  }),
};
