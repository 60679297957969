/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import $ from 'jquery';
import { getAccessToken } from '../utils';
import { MarketDashboardBlockType } from './enums';
import { DashboardData } from './mockData/dashboardData';
import { Flat, FlatResponse } from './mockData/flatsData';
import { OverviewData } from './mockData/overviewData';
import { PriceListTableData } from './mockData/priceListData';
import { PriceListDetailData } from './mockData/priceListDetailData';
import { PriceProgressData } from './mockData/priceProgressData';
import { Project } from './mockData/projectData';
import { SaleProgressData } from './mockData/saleProgressData';
import {
  CreateMarketDashboardParameters,
  CreateSalesTarget,
  DailyNewsGpsTemplate,
  DailyNewsPartialTemplate,
  DailyNewsTemplate,
  DashboardDataParameters,
  DaysOnMarketResponse,
  DeleteSalesTarget,
  DeleteSubscribedEmailsParameters,
  DemandDataResponse,
  FloorOverviewResponse,
  GetPriceListDataParams,
  GetPriceListUpdateDataParams,
  LoginResponse,
  LoginValues,
  MapResponse,
  MarketDashboardOverview,
  MarketDashboardSettings,
  MarketDashboardTemplate,
  MarketProject,
  MarketShareData,
  MarketShareSaleSpeedData,
  OverviewDataResponse,
  PriceComparisonData,
  PriceHistoryData,
  PriceHistoryPrimaryData,
  Profile,
  SaleDetailDataResponse,
  SaleSpeedData,
  SaleSpeedDetailData,
  SizeComparisonData,
  SubscribedEmails,
  SupplyDataResponse,
  SupplyHistoryDataResponse,
  UnitMixData,
  UpdateMarketDashboardParameters,
  UpdateProfile,
  UpdateSalesPlan,
  UpdateSalesPlanDate,
  UpdateSalesTargets,
  URLResponse,
} from './types';
import { MappedGpsData } from '../store/dailyNews';
import { MultiPolygonalSchema } from './secondaryClient';
import { PricePerSmCalcType } from '../components/TopBar/SettingsPopover';
import { PriceListUpdateData } from './mockData/priceUpdateData';
import { PlanData, ProjectPlans, ProjectTargets, Target, Targets } from './mockData/sales';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: (params) => $.param(params, true),
});

export const S3axiosInstance: AxiosInstance = axios.create({
  paramsSerializer: (params) => $.param(params, true),
});

// Define a custom Axios request configuration interface
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  retryCount?: number;
}

// Maximum number of retries for 403 errors
const MAX_RETRIES = 2;

axiosInstance.interceptors.response.use(
  undefined,
  (error: AxiosError) => {
    const { config, response } = error;

    if (!config || !response) {
      return Promise.reject(error);
    }

    // Handle 403 error: Retry request
    if (response.status === 403) {
      const customConfig = config as CustomAxiosRequestConfig; // Cast to custom interface
      customConfig.retryCount = customConfig.retryCount || 0;

      if (customConfig.retryCount < MAX_RETRIES) {
        customConfig.retryCount += 1;
        return axios.request(customConfig);
      }
    }

    // For other errors or if max retries reached, reject the promise
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.request.use(
  async (config) => {
    /* config.headers = { 'x-language': i18next.language }; */
    const token = getAccessToken();
    if (token) {
      config.headers = { Authorization: token, ...config.headers };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

/* const mock = new MockAdapter(axiosInstance, { delayResponse: 1000 }); */

export const fetchOverviewData = async (
  developerId: number,
): Promise<AxiosResponse<OverviewData>> =>
  axiosInstance.get<OverviewData>(`/overview/${developerId}`);

export const login = async (
  values: LoginValues,
): Promise<AxiosResponse<LoginResponse>> =>
  axiosInstance.post<LoginResponse>('/login', values);

export const fetchProfile = async (): Promise<AxiosResponse<Profile>> =>
  axiosInstance.get<Profile>('/profile');

export const getSubscribedEmails = async (
  developerId: number,
): Promise<AxiosResponse<SubscribedEmails>> =>
  axiosInstance.get<SubscribedEmails>('/profile/emails', { params: { developer_id: developerId } });

export const deleteSubscribedEmails = async ({
  developerId,
  emails,
}: DeleteSubscribedEmailsParameters): Promise<AxiosResponse> =>
  axiosInstance.delete('/profile/emails', {
    params: { developer_id: developerId, emails_to_delete: emails },
  });

export const updateProfile = async (
  data: UpdateProfile,
): Promise<AxiosResponse<UpdateProfile>> =>
  axiosInstance.put<UpdateProfile>('/profile', data);

export const getUploadUrl = async (): Promise<AxiosResponse<URLResponse>> =>
  axiosInstance.get<URLResponse>('/internal_data_url');

export const fetchMarketProjects = async (
  cities: string[],
  simply?: boolean,
): Promise<AxiosResponse<MarketProject[]>> =>
  axiosInstance.get('/market_dashboard/list_projects/map_zone', {
    params: { starting_point: cities, simply: simply ?? false },
  });

// will be used in the future to enhance paggination
export const fetchMarketDashboard = async (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<MarketDashboardSettings>> =>
  axiosInstance.get('/market_dashboards/dashboard_data', {
    params: { dashboard_id: dashboardId, date, price_per_sm_calculation },
  });

  export const fetchMarketDashboardSimplified = async (
    dashboardId: number,
    date: string,
    price_per_sm_calculation: PricePerSmCalcType,
    VAT_included: boolean | undefined,
    reserved_as_sold: boolean | undefined,
  ): Promise<AxiosResponse<MarketDashboardSettings>> =>
    axiosInstance.get('/market_dashboards/settings', {
      params: {
        dashboard_id: dashboardId,
        date,
        price_per_sm_calculation,
        VAT: VAT_included,
        reserved_as_sold,
      },
    });

export const createMarketDashboard = async ({
  included_project_ids,
  remaining_project_ids,
  dashboard_name,
  include_new_projects,
  geometry,
}: CreateMarketDashboardParameters): Promise<
  AxiosResponse<{ dashboardId: number }>
> =>
  axiosInstance.post('/market_dashboards/dashboard_data', {
    dashboard_name,
    included_project_ids,
    remaining_project_ids,
    include_new_projects,
    geometry,
  });

export const updateMarketDashboard = async (
  data: UpdateMarketDashboardParameters,
): Promise<AxiosResponse> =>
  axiosInstance.put('/market_dashboards/dashboard_data', data);

export const deleteMarketDashboard = async (
  dashboardId: number,
): Promise<AxiosResponse> =>
  axiosInstance.delete('/market_dashboards/dashboard_data', {
    data: { dashboard_id: dashboardId },
  });

export const getFlats = async (
  dashboardId: number,
  date: string,
): Promise<AxiosResponse<FlatResponse>> =>
  axiosInstance.get('/market_dashboards/flats', {
    params: { dashboard_id: dashboardId, date },
  });

export const getFlatsFromS3 = async (
  s3url: string,
): Promise<AxiosResponse<Flat[]>> =>
  S3axiosInstance.get(s3url);

export const getProject = async (
  projectId: number,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse> =>
  axiosInstance
    .get<Project>('/project/data', {
      params: { project_id: projectId, price_per_sm_calculation },
    })
    .then((response) => {
      // const transformedData = response.data;
      // transformedData.price_list = transformedData.price_list.map((value) =>
      //   value.layout === null ? { ...value, layout: 'unknown' } : value,
      // );
      const transformedData = response.data ? {
        ...response.data,
        price_list: response.data.price_list.map((item) => {
          const updatedItem = item.layout === null ? { ...item, layout: 'unknown' } : item;
          // for projects where total_area is used
          if (response.data.using_total_area) {
            const total_area = item.total_area - (item.exterior_area ?? 0);
            if (total_area === 0) {
              return {
                ...updatedItem,
                floor_area: null,
                price_per_sm: null,
              };
            }
            return {
              ...updatedItem,
              floor_area: total_area,
              price_per_sm: item.price ? item.price / total_area : null,
            };
          }
          return updatedItem;
        }),
      } : null;
      return { ...response, data: transformedData };
    });

export const getSaleProgressChartData = async (
  projectId: number,
): Promise<AxiosResponse<SaleProgressData[]>> =>
  axiosInstance.get('/project/sale_progress', {
    params: { project_id: projectId },
  });

export const getPriceProgressChartData = async (
  projectId: number,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<PriceProgressData[]>> =>
  axiosInstance.get('/project/price_progress', {
    params: { project_id: projectId, price_per_sm_calculation },
  });

export const getDashboardData = async ({
  projectId,
  phaseId,
  date,
  price_per_sm_calculation,
}: DashboardDataParameters): Promise<AxiosResponse<DashboardData>> =>
  axiosInstance.get('/dashboard', {
    params: { project_id: projectId, phase_id: phaseId, date, price_per_sm_calculation },
  });

export const getPriceListData = async ({
  projectId,
  phaseId,
  date,
  price_per_sm_calculation,
}: GetPriceListDataParams): Promise<AxiosResponse<PriceListTableData[]>> =>
  axiosInstance.get('/price-list/table', {
    params: { project_id: projectId, phase_id: phaseId, date, price_per_sm_calculation },
  });

export const getPriceListDetailData = async (
  id: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<PriceListDetailData>> =>
  axiosInstance.get<PriceListDetailData>('/price-list/detail', {
    params: { unit_id: id, date, price_per_sm_calculation },
  });

export const getPriceListUpdateData = async ({
  price_list_update_id,
}: GetPriceListUpdateDataParams): Promise<AxiosResponse<PriceListUpdateData[]>> =>
  axiosInstance.get('/price-list/pricelist_update', {
    params: { price_list_update_id },
  });

export const updatePriceListUserSeen = async ({
  price_list_update_id,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: GetPriceListUpdateDataParams): Promise<AxiosResponse<any>> => axiosInstance.put('/price-list/pricelist_update', { price_list_update_id, price_list_update_has_seen: true });

export const fetchTemplate = async (
  id: number,
): Promise<AxiosResponse<MarketDashboardTemplate>> =>
  axiosInstance.get('/market_dashboards/templates', {
    params: { template_id: id },
  });

export const fetchBlockMapData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<MapResponse>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.MAP,
      date,
      price_per_sm_calculation,
    },
  });

export const fetchBlockPriceComparisonData = (
  dashboardId: number,
  date: string,
  projectIds: number[],
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<PriceComparisonData[]>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.PRICE_COMPARISON,
      date,
      project_ids: projectIds,
      price_per_sm_calculation,
    },
  });

export const fetchBlockSizeComparisonData = (
  dashboardId: number,
  date: string,
  projectIds: number[],
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<SizeComparisonData[]>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.SIZE_COMPARISON,
      date,
      project_ids: projectIds,
      price_per_sm_calculation,
    },
  });

export const fetchBlockUnitMixData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<UnitMixData[]>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.UNIT_MIX,
      date,
      price_per_sm_calculation,
    },
  });

export const fetchBlockMarketShareData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<MarketShareData[]>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.MARKET_SHARE,
      date,
      price_per_sm_calculation,
    },
  });

export const fetchBlockMarketShareSaleSpeedData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<MarketShareSaleSpeedData[]>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.MARKET_SHARE_SALE_SPEED,
      date,
      price_per_sm_calculation,
    },
  });

export const fetchBlockPriceDistributionData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.PRICE_DISTRIBUTION,
      date,
      price_per_sm_calculation,
    },
  });

export const fetchBlockPriceHistoryData = (
  dashboardId: number,
  date: string,
  projectIds: number[],
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<PriceHistoryPrimaryData[]>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.PRICE_HISTORY,
      date,
      project_ids: projectIds,
      price_per_sm_calculation,
    },
  });

export const fetchBlockSaleSpeedData = (
  dashboardId: number,
  date: string,
  projectIds: number[],
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<SaleSpeedData[]>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.SALE_SPEED,
      date,
      project_ids: projectIds,
      price_per_sm_calculation,
    },
  });

export const fetchDetailSaleSpeedData = (
  dashboardId: number,
  date: string,
  year_month: string,
  availability: string,
  types: string[],
): Promise<AxiosResponse<SaleSpeedDetailData>> =>
  axiosInstance.get('/market_dashboards/sales', {
    params: {
      dashboard_id: dashboardId,
      date,
      year_month,
      availability,
      types: JSON.stringify(types),
    },
  });

export const fetchBlockProjectDetailData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.PROJECTS_DETAIL,
      date,
      price_per_sm_calculation,
    },
  });

export const fetchBlockOverviewData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<OverviewDataResponse>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.OVERVIEW,
      date,
      price_per_sm_calculation,
    },
  });

export const fetchBlockSupplyData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<SupplyDataResponse>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.SUPPLY,
      date,
      price_per_sm_calculation,
    },
  });

export const fetchBlockDemandData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<DemandDataResponse>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.DEMAND,
      date,
      price_per_sm_calculation,
    },
  });

export const fetchBlockSupplyHistoryData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<SupplyHistoryDataResponse>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.SUPPLY_HISTORY,
      date,
      price_per_sm_calculation,
    },
  });

export const fetchBlockSaleDetailData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<SaleDetailDataResponse>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.SALE_DETAIL,
      date,
      price_per_sm_calculation,
    },
  });
export const fetchBlockFloorOverviewData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<FloorOverviewResponse>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.FLOOR_OVERVIEW,
      date,
      price_per_sm_calculation,
    },
  });
export const fetchBlockDaysOnMarketData = (
  dashboardId: number,
  date: string,
  price_per_sm_calculation: PricePerSmCalcType,
): Promise<AxiosResponse<DaysOnMarketResponse>> =>
  axiosInstance.get('/market_dashboards/blocks', {
    params: {
      dashboard_id: dashboardId,
      block_type: MarketDashboardBlockType.DAYS_ON_MARKET,
      date,
      price_per_sm_calculation,
    },
  });
export const fetchMarketDashboardOverview = (
  dashboardId: number,
): Promise<AxiosResponse<MarketDashboardOverview>> =>
  axiosInstance.get('/market_dashboards/overview', {
    params: {
      dashboard_id: dashboardId,
    },
  });

export const fetchUserMDTemplates = (): Promise<
  AxiosResponse<MarketDashboardTemplate[]>
> => axiosInstance.get('/market_dashboards/templates/list');

export const getDailyNewsData = async <T extends keyof DailyNewsTemplate>(
  cityId: number,
  segment: T,
): Promise<AxiosResponse<{ [K in T]: DailyNewsTemplate[T] }>> => axiosInstance.get<{ [K in T]: DailyNewsTemplate[T] }>(`/daily_news/${cityId}`, {
  params: {
    segment,
  },
});

export const getDailyNewsDashboardData = async <T extends keyof DailyNewsTemplate>(
  dashboardId: number,
  segment: T,
): Promise<AxiosResponse<{ [K in T]: DailyNewsTemplate[T] }>> => axiosInstance.get<{ [K in T]: DailyNewsTemplate[T] }>(`/daily_news/${dashboardId}`, {
  params: {
    segment,
  },
});

export const getDailyNewsGpsData =
  async (city: string): Promise<MappedGpsData[]> => {
    let responseData;
    const { data } = await axiosInstance.get<DailyNewsGpsTemplate | string>(`/gps/by_city/${city}`);
    if (typeof data === 'string') {
      const string = data.replace(/NaN/g, '0');
      responseData = JSON.parse(string);
      responseData = Object.entries(responseData.projects_coords as DailyNewsGpsTemplate)
        .map(([key, value]) => ({
          project_name: value.name,
          project_id: key,
          ...value,
          include: true,
        }))
        // eslint-disable-next-line no-restricted-globals
        .filter((d) => !isNaN(d.gps_latitude) && !isNaN(d.gps_longitude)
          && typeof d.gps_latitude === 'number' && typeof d.gps_longitude === 'number');
    } else {
      responseData = Object.entries(data.projects_coords as DailyNewsGpsTemplate['projects_coords']).map(([key, value]) => ({
        project_name: value.name,
        project_id: key,
        ...value,
        include: true,
      }));
    }
    return responseData;
  };

export const getDailyNewsDataPartial =
  (cityId: number): Promise<AxiosResponse<DailyNewsPartialTemplate>> =>
    axiosInstance.get(`/daily_news/parts/${cityId}`);

export const getReportsSidebarOrdering = async (): Promise<Array<{ dashboard_name: string, ordering: number }>> => {
  const { data } = await axiosInstance.get('/profile/ordering');
  return data;
};

export const setReportsSidebarOrdering = async (
  data: { order: Record<string, number> },
): Promise<AxiosResponse> => axiosInstance.put('/profile/ordering', data);

export const fetchSalesTargets = async (): Promise<AxiosResponse<ProjectTargets>> =>
  axiosInstance.get<ProjectTargets>('/sales_targets');

export const updateSalesTargets = async (
  data: UpdateSalesTargets,
): Promise<AxiosResponse<Targets[]>> => axiosInstance.put<Targets[]>('/sales_targets', data);

export const updateSalesPlan = async (
  data: UpdateSalesPlan,
): Promise<AxiosResponse<PlanData[]>> => axiosInstance.put<PlanData[]>('/sale_plans', data);

export const updateSalesPlanDate = async (
  data: UpdateSalesPlanDate,
): Promise<AxiosResponse> => axiosInstance.put('/sale_plans', data);

export const createSalesTarget = async (
  data: CreateSalesTarget,
): Promise<AxiosResponse<Target>> =>
  axiosInstance.post<Target>('/sales_targets', data);

export const deleteSalesTarget = async (
  data: DeleteSalesTarget,
): Promise<AxiosResponse> =>
  axiosInstance.delete('/sales_targets', { data: { sale_target_id: data.sale_target_id } });

export const fetchSalesPlan = async (): Promise<AxiosResponse<ProjectPlans>> =>
  axiosInstance.get<ProjectPlans>('/sale_plans');

export const createOnboardingProject = async ({
  developer_id,
  project,
}: any): Promise<AxiosResponse<any>> =>
  axiosInstance.post(`/pricing-input/${developer_id}`, {
    project,
  });
