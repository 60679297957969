import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Project } from '../../api/mockData/projectData';
import { Card } from '../Card';
import { Content } from '../map/ProjectDetail/Content';
import { Export } from './Export';
import {
  MapPorjectStatusTag,
  TAGS_MOCK,
} from '../MapControls/MapProjectStatusTags';

interface Props {
  data: Project;
}

export const Overview: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Card
      title={t('project.overview.title', 'Project Overview')}
      extra={<Export />}
      subtitle={
        <div className='flex gap-2 items-center'>
          {data?.project_phase && (
            <div className='py-2'>
              <MapPorjectStatusTag
                tag={data?.project_phase}
                color={
                  TAGS_MOCK.find((tag) => tag.name === data?.project_phase)
                    ?.color ?? '#5A72B1'
                }
              />
            </div>
          )}
          {data?.discounts && !!data?.discounts[0]?.discount_text && (
            <div
              className='daily_news_list_item_container_value'
              style={{ margin: '.5em 0' }}
            >
              <div className='daily_news_list_item_value border'>
                <div className='daily_news_list_item_trans'>
                  <div className='daily_news_icon'>
                    <img
                      style={{ width: '18px', height: '18px' }}
                      src='../assets/price-tag-dark-blue.png'
                      alt='price_tag'
                    />
                  </div>
                  <div style={{ paddingRight: '1em' }}>
                    <b>{data.discounts[0].discount_text}</b>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    >
      <Content data={data} />
    </Card>
  );
};
