import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../hooks';
import { ProjectPhase } from '../../api/types';

export const TAGS_MOCK = [
  { name: ProjectPhase.COMPLETED, color: '#5A72B1' },
  { name: ProjectPhase.DEVELOPMENT, color: '#9EB9DA' },
  { name: ProjectPhase.PLANNED, color: '#FFFFFF' },
  { name: ProjectPhase.ARCHIVED, color: '#828282' },
];

export enum MapType {
  PROJECTS = 'Projects',
  CREATE_REPOROT = 'Create report',
  DAILY_NEWS = 'Daily news',
  REPORT = 'Report',
}

type TagProps = {
  tag: string;
  color: string;
};

export const MapPorjectStatusTag: FC<TagProps> = ({ tag, color }) => {
  const { t } = useTranslation();
  return (
    <div className='text-bmblue pointer-events-auto w-fit flex gap-2 py-1 pr-4 pl-3 items-center bg-white rounded-[25px] drop-shadow border border-bmblue'>
      <span
        className={clsx(
          'h-6 w-6 rounded-full',
          tag === 'Planned' ? 'border-bmblue border-[1px]' : '',
        )}
        style={{ backgroundColor: color }}
      />
      {t(`market.reports.project_phase.${tag.toLowerCase()}`, tag)}
    </div>
  );
};

type Props = {
  mapType?: MapType;
};

export const MapProjectStatusTags: FC<Props> = ({ mapType }) => {
  const { availableMapTags } = useStoreState((state) => state.market);
  const isCreateReport = mapType === MapType.CREATE_REPOROT;

  // Filter TAGS_MOCK to include only the tags available in availableMapTags
  const filteredTags = TAGS_MOCK.slice(0, isCreateReport ? 3 : 4).filter(
    (tag) => availableMapTags.includes(tag.name),
  );

  return (
    <div className='flex gap-4 h-fit flex-wrap'>
      {filteredTags.map((tag) => (
        <MapPorjectStatusTag key={tag.name} tag={tag.name} color={tag.color} />
      ))}
    </div>
  );
};
