import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoPopover } from '../../../../../InfoPopover';

export const PublicTransport: FC<{ region: string }> = ({ region }) => {
  const { t } = useTranslation();

  return (
    <div>
      <InfoPopover
        label={t('project.overview.public_transport', 'Public Transport')}
        popoverProps={{
          content: t(
            'project.overview.info.public_transport',
            'The time it takes to get to the center of {{region}} by public transport on a workday. Source: Google Maps.',
            { region },
          ),
        }}
      />
    </div>
  );
};
